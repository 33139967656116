<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">Program Owners</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'program-owner-create' }">
                <button v-show="!loading" type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['program-owner-create'])">
                  <i class="mdi mdi-plus-circle"></i> Add Program Owner
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive">
              <table class="table table-striped dt-responsive w-100 mb-3" id="module-datatable" v-show="!loading">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Program Name</th>
                    <th>Short Name</th>
                    <th>Trainer</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th v-if="hasPermissions(['program-owner-edit'])">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(ProgramOwner, index) in ProgramOwners" :key="index">
                    <tr v-if="ProgramOwner.trainer != null">
                      <td>{{ ++index }}</td>
                      <td>{{ ProgramOwner.program_name }}</td>
                      <td>{{ ProgramOwner.program_short_name }}</td>
                      <td>{{ ProgramOwner.trainer.user.name }}</td>
                      <td>{{ ProgramOwner.trainer.user.email == "null" ? '-'  : ProgramOwner.trainer.user.email }}</td>
                      <td>
                        <badgeSuccess v-if="ProgramOwner.trainer.status == 1" :name="'Active'" />
                        <badgeDanger v-else :name="'Inactive'" />
                      </td>
                      <td v-if="hasPermissions(['program-owner-edit'])">
                        <span>
                          <router-link class="action-icon" :to="{
                            name: 'program-owner-update',
                            params: { id: ProgramOwner.id },
                          }">
                            <i class="mdi mdi-square-edit-outline"></i>
                          </router-link>
                        </span>
                        <!-- <span @click="deleteProgramOwner(ProgramOwner.id)">
                          <i class="fas fa-trash-alt mx-1"></i>
                        </span> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable ProgramOwners
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      ProgramOwners: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllProgramOwners() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/program-owners`)
        .then((response) => {
          this.ProgramOwners = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
        });
      $("#module-datatable").DataTable();
    },
    // async deleteProgramOwner(id) {
    //   axios
    //     .delete(`${this.baseUrl}admin/v1/program-owners/` + id, {})
    //     .then(() => {
    //       this.toast.success("Successfully Module Group Deleted!");
    //       this.getAllProgramOwners();
    //     })
    //     .catch((error) => {
    //       this.errors = error.response.data.errors;
    //       this.toast.error("Something Went Wrong!");
    //     });
    // },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#module-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllProgramOwners();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

</style>
